
import { register } from './view'

import ArticleAccordion from '../views/article/accordion/accordion'
import ComponentCalendar from '../views/component/calendar/calendar'
import ComponentSearch from '../views/component/search/search'
import ComponentTeaserGrid from '../views/component/teaser-grid/teaser-grid'
import ModuleListing from '../views/module/listing/listing'

import 'lazysizes'
import './browser'

// Register components
register('calendar', ComponentCalendar)
register('search', ComponentSearch)
register('teaser-grid', ComponentTeaserGrid)

// Register modules
register('module-listing', ModuleListing)

// Register article parts
register('article-accordion', ArticleAccordion)
